import React from "react"

const Privacy = () => {
	return (
		<div className="min-h-screen bg-surface1 pt-24 px-4">
			<div className="max-w-4xl mx-auto pb-24">
				<h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-12 text-center">
					<span className="galaxy-text">Privacy Policy</span>
				</h1>

				<div className="prose prose-invert max-w-none">
					<section className="mb-8">
						<p className="text-base mb-6">
							Welcome to JIG JOY D.O.O. ("we," "us," or "our"). We respect your privacy and are committed to protecting your personal
							data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our
							product, JIG JOY D.O.O., which is made available for customers in the United States and Europe. Please read this Privacy
							Policy carefully. By using JigJoy, you acknowledge that you have read, understood, and agree to be bound by all
							the terms of this policy.
						</p>
						<p className="text-base mb-6">
							If you do not agree with the terms of this Privacy Policy, please do not access or use JIG JOY D.O.O.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">1. Scope</h2>
						<p className="text-base mb-4">This Privacy Policy applies to information we collect through:</p>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Our website (https://jigjoy.io)</li>
							<li>Any other product or service under the JIG JOY D.O.O. brand that references or links to this Privacy Policy</li>
						</ul>
						<p className="text-base">
							It also describes your data protection rights, including the right to object to some of the processing we carry
							out. For more information about your rights and how to exercise them, see Your Rights below.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">2. Information We Collect</h2>
						<p className="text-base mb-4">We may collect and process the following types of information:</p>

						<h3 className="text-xl font-bold mb-3 text-heading">Personal Data:</h3>
						<p className="text-base mb-4">Information that can be used to identify you directly or indirectly.</p>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Contact Information: Name, email address, mailing address, phone number (if you provide them).</li>
							<li>
								Payment Data: Payment method details (credit/debit card, PayPal, etc.) when you make purchases. This information
								is typically processed by our trusted payment processors and may not be stored by JigJoy directly.
							</li>
						</ul>

						<h3 className="text-xl font-bold mb-3 text-heading">Usage Data:</h3>
						<p className="text-base mb-4">Information about how you access and use JigJoy.</p>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Device Information: IP address, browser type, device type, operating system, unique device identifiers.</li>
							<li>
								Log Information: Pages viewed, links clicked, time spent on the application or site, and other usage statistics.
							</li>
							<li>
								Location Data: Approximate location (e.g., city, country) derived from your IP address or GPS data (where you
								have provided permission for this).
							</li>
						</ul>
						<p className="text-base">
							Cookies and Similar Technologies: We use cookies, web beacons, and other tracking technologies to collect
							information about your interactions with JigJoy. For more details, see our Cookie Policy.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">3. How We Use Your Information</h2>
						<p className="text-base mb-4">We use your information for the following purposes:</p>

						<h3 className="text-xl font-bold mb-3 text-heading">To Provide and Maintain Our Services</h3>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Create and manage your user account.</li>
							<li>Process transactions (purchases, subscriptions).</li>
							<li>Ensure the technical functionality and security of JigJoy.</li>
						</ul>

						<h3 className="text-xl font-bold mb-3 text-heading">To Improve, Personalize, and Develop Our Services</h3>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Analyze how you use JigJoy to improve functionalities and user experience.</li>
							<li>
								Tailor content and features to you (e.g., personalized puzzle recommendations, targeted in-app messages).
							</li>
						</ul>

						<h3 className="text-xl font-bold mb-3 text-heading">To Communicate With You</h3>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Respond to your inquiries, support requests, or feedback.</li>
							<li>Send administrative information, such as updates to our terms and policies.</li>
							<li>
								Provide marketing communications about new features or promotions (where we have the legal basis to do so).
							</li>
						</ul>

						<h3 className="text-xl font-bold mb-3 text-heading">To Comply With Legal Obligations</h3>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Comply with applicable laws, regulations, and legal processes.</li>
							<li>Enforce our terms and policies, protect against legal liability, and safeguard our rights and interests.</li>
						</ul>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">4. Legal Bases for Processing (EEA/UK Users)</h2>
						<p className="text-base mb-4">
							If you are located in the European Economic Area (EEA) or the United Kingdom (UK), we only process your personal
							data when we have a valid legal basis, including:
						</p>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Consent: Where you have given us consent (e.g., for marketing communications).</li>
							<li>
								Contract: Where processing is necessary for the performance of a contract with you or to take steps prior to
								entering into a contract (e.g., to provide our services that you requested).
							</li>
							<li>Legal Obligation: Where we are required by law (e.g., tax or financial reporting).</li>
							<li>
								Legitimate Interests: Where processing is necessary for our legitimate interests (e.g., ensuring the security of
								our services, improving user experience), unless those interests are overridden by your interests or fundamental
								rights and freedoms.
							</li>
						</ul>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">5. How We Share Your Information</h2>
						<p className="text-base mb-4">We may share the information we collect in the following circumstances:</p>

						<h3 className="text-xl font-bold mb-3 text-heading">Service Providers</h3>
						<p className="text-base mb-4">
							We may share information with third-party vendors, consultants, and other service providers who perform services
							on our behalf (e.g., payment processing, data analysis, email delivery, hosting).
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">Business Transfers</h3>
						<p className="text-base mb-4">
							We may share or transfer information in connection with any merger, sale of company assets, financing, or
							acquisition of all or a portion of our business to another company.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">Legal Requirements</h3>
						<p className="text-base mb-4">
							We may disclose your information if we believe it is necessary to comply with a legal obligation, protect our
							rights or property, or protect the personal safety of users or the public.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">With Your Consent</h3>
						<p className="text-base mb-4">
							We may disclose your information for any other purpose with your explicit consent.
						</p>

						<p className="text-base">
							We will never sell your personal data to third parties for their own marketing purposes.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">6. International Transfers</h2>
						<p className="text-base mb-6">
							Your personal information may be transferred to, stored, or processed in countries other than the one in which it
							was originally collected. These countries may have different data protection laws than your country of residence.
							When we transfer your personal data internationally (including to the U.S.), we take legally required steps to
							ensure adequate safeguards are in place, such as the use of the European Commission's Standard Contractual
							Clauses (SCCs) or reliance on an adequacy decision by the European Commission.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">7. Data Retention</h2>
						<p className="text-base mb-6">
							We will retain your personal data only for as long as is necessary to fulfill the purposes outlined in this
							Privacy Policy, or for as long as we have a legal obligation to do so. When we no longer need your personal data,
							we will either delete or anonymize it, or if this is not possible, securely store your personal data and isolate
							it from any further use until deletion is possible.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">8. Your Rights</h2>
						<p className="text-base mb-4">
							Depending on where you reside, you may have the following rights regarding your personal data:
						</p>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>Access: Request access to the personal data we hold about you.</li>
							<li>Rectification: Request that we correct or update any personal data that is inaccurate or incomplete.</li>
							<li>
								Erasure ("Right to Be Forgotten"): Request that we delete your personal data when there is no compelling reason
								for us to keep it.
							</li>
							<li>Restriction of Processing: Ask us to restrict further processing of your personal data.</li>
							<li>
								Data Portability: Request a copy of your personal data in a structured, commonly used, and machine-readable
								format.
							</li>
							<li>Objection: Object to certain types of processing (e.g., direct marketing).</li>
							<li>
								Withdraw Consent: If we rely on consent to process your personal data, you have the right to withdraw that
								consent at any time.
							</li>
						</ul>
						<p className="text-base">
							To exercise any of these rights, please contact us using the details in Contact Us. We may ask you to verify your
							identity before responding to such requests. We will respond to your request within a reasonable timeframe and in
							accordance with applicable law.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">9. Cookie Policy</h2>
						<p className="text-base mb-6">
							We use cookies and similar tracking technologies to collect and use personal data about you, including to serve
							interest-based advertising. For more information about the types of cookies we use, why we use them, and how you
							can control cookies, please see our [Cookie Policy]. (If not available, include details here or link to a
							separate page.)
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">10. Security</h2>
						<p className="text-base mb-6">
							We use appropriate technical and organizational measures to protect your personal data against accidental or
							unlawful destruction, loss, alteration, or unauthorized disclosure or access. However, no data transmission or
							storage system can be guaranteed to be 100% secure. If you believe your interaction with JigJoy is no longer
							secure, please contact us immediately.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">11. Children's Privacy</h2>
						<p className="text-base mb-6">
							JIG JOY D.O.O. is not directed toward individuals under the age of 16. We do not knowingly collect personal information
							from children under 16 without verifiable parental consent. If we become aware that a child under 16 has provided
							us with personal information without parental consent, we will delete such information from our files. If you
							believe we have collected personal data from a child, please contact us immediately.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">12. Changes to This Privacy Policy</h2>
						<p className="text-base mb-6">
							We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, legal
							requirements, and other factors. When we do, we will revise the "Effective Date" at the top of this Privacy
							Policy and post the updated version. Your continued use of JigJoy after any changes to the Privacy Policy
							indicates your acceptance of the updated terms.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">13. Contact Us</h2>
						<p className="text-base mb-4">
							If you have questions or comments about this Privacy Policy or our data practices, please contact us at:
						</p>
						<div className="text-base">
							<p>JIG JOY D.O.O.</p>
							<p>Novi Sad, Fruškogorska 1</p>
							<p>Science and Technology Park</p>
							<p>
								<a href="mailto:team@jigjoy.io" className="text-clickable hover:text-hover transition-colors">
									team@jigjoy.io
								</a>
							</p>
						</div>
					</section>

					<section className="mt-12 pt-8 border-t border-outline/20">
						<p className="text-sm text-base/50 text-center">Last Updated: 24.01.2025</p>
					</section>
				</div>
			</div>
		</div>
	)
}

export default Privacy
